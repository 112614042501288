import { getApiDomain, getJWT, isAuthenticated } from "utils";

const BASE_API_URL = getApiDomain()

const headers = {
    'Content-Type': 'application/json;charset=utf-8',
}

if (isAuthenticated()) {
    headers["Authorization"] = `Bearer ${getJWT()}`;
}

export const getCustomerInformationBySalesOrder = async (salesOrderName) => {
    const result = await fetch(
        `${BASE_API_URL}/erp/saleorders/${salesOrderName}`, {
            method: "GET",
            headers
        }
    )
    return await result.json()
}

export const createRemitaRRR = async (payload) => {
    const result = await fetch(
        `${BASE_API_URL}/remita/generate`, {
            method: "POST",
            headers,
            body: JSON.stringify(payload)
        }
    )
    return await result.json()
}

export const postRemitaERPTransaction = async (salesOrderName, payload) => {
    const result = await fetch(
        `${BASE_API_URL}/erp/saleorders/${salesOrderName}/remita-transactions`, {
            method: "POST",
            headers,
            body: JSON.stringify(payload)
        }
    )
    return await result.json()
}




