import React from "react";

const LandingPageContainer = ({children}) => {
  

  return (
    <div className="w-full min-h-screen flex flex-col md:flex-row bg-white ">
      <div className="my-auto w-6/6 md:w-3/6">
        <img
          className="object-cover"
          alt="landingpage"
          src={`${process.env.PUBLIC_URL}/payments.svg`}
        />
      </div>
      <div className="w-6/6 md:w-3/6 bg-white p-8 min-h-screen flex flex-row justify-center items-center">
        <div className="flex flex-col">
          {children}
        </div>
      </div>
    </div>
  );
};
export default LandingPageContainer;
