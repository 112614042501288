import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import LandingPageContainer from "./LandingPageContainer";
import { faCreditCard, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import Button from "ui/Button";
import { remitaDemoKey } from "data/config";
import {
  createRemitaRRR,
  getCustomerInformationBySalesOrder,
  postRemitaERPTransaction,
} from "apis";
import AuthContext from "context/AuthContext";

function SalesDetailPage() {
  const history = useHistory();
  const { saledOrderId } = useParams();
  const [salesOrderInfo, setSaleOrderInfo] = useState({});
  console.log("@@ sale order ", saledOrderId);

  const fetchSaleOrder = async () => {
    const res = await getCustomerInformationBySalesOrder(saledOrderId);
    console.log("@@ here now ", res);
    if (res?.name) {
      setSaleOrderInfo(res);
    } else {
      alert("Error no sales order found");
    }
  };

  const generateRRRR = async ({ salesOrder, project, amount }) => {
    const res = await createRemitaRRR({
      sales_order: salesOrder,
      project,
      amount,
    });
    const { RRR, statuscode } = res;

    payUsingRemitaWithRRR({
      salesOrderName: salesOrder,
      generatedRRR: RRR,
      transactionId: `${salesOrder}-${Math.floor(Math.random() * 1101233)}`,
    });

    console.log("## res", res);
  };

  const createRemitaPaymentLog = (details) => {
    postRemitaERPTransaction(details?.salesOrderName, details)
      .then((res) => {
        console.log("Remita Transaction Response", res);
        alert(
          "Your payment was successufull. The team will confirm your payment"
        );
        history.push("/");
      })
      .catch((err) => {
        console.log("Payment could not be completed");
      });
  };

  function payUsingRemitaWithRRR({
    salesOrderName = "N-A",
    generatedRRR = "",
    transactionId,
    publicKey,
  }) {
    var paymentEngine = window.RmPaymentEngine.init({
      // change this with live key from Remita
      key: publicKey || remitaDemoKey,
      transactionId,
      processRrr: true,
      extendedData: {
        customFields: [
          {
            name: "rrr",
            value: generatedRRR,
          },
        ],
      },

      onSuccess: function (response) {
        createRemitaPaymentLog({
          ...response,
          salesOrderName,
          rrr: generatedRRR,
        });
      },
      onError: function (response) {
        alert("Error processing your payment try again!!!");
        console.log("callback Error Response", response);
      },
      onClose: function () {
        console.log("closed");
      },
    });
    paymentEngine.showPaymentWidget();
  }

  const formik = useFormik({
    initialValues: {
      amount: 100000,
    },

    async onSubmit(values) {
      await generateRRRR({
        salesOrder: salesOrderInfo?.name,
        project: salesOrderInfo?.project,
        amount: values?.amount,
      });
    },
  });

  useEffect(() => {
    fetchSaleOrder();
  }, [saledOrderId]);

  const hasSaleOrderLoaded = salesOrderInfo?.name;

  return (
    <LandingPageContainer>
      <div>
        {hasSaleOrderLoaded ? (
          <div className="flex flex-col border-2 px-4 py-6">
            <div>
              <h1 className="text-3xl uppercase ml-3 text-msssl font-bold text-center mb-3">
                Remita Payment for {salesOrderInfo?.items[0]?.description}
              </h1>
            </div>
            <div>
              <InfoWithHeaderTitle
                title={"Customer Name"}
                description={salesOrderInfo?.customer_name}
              />

              <InfoWithHeaderTitle
                title={"Customer Address"}
                description={salesOrderInfo?.address_display}
              />
            </div>
            <div>
              <InfoWithHeaderTitle
                title={"Email"}
                description={salesOrderInfo?.contact_email}
              />
            </div>

            <div>
              <InfoWithHeaderTitle
                title={"Project"}
                description={salesOrderInfo?.cost_center}
              />

              <InfoWithHeaderTitle
                title={"Location"}
                description={salesOrderInfo?.location}
              />
              <InfoWithHeaderTitle
                title={"Grand Total"}
                description={`NGN ${salesOrderInfo?.base_total}`}
              />

              <InfoWithHeaderTitle
                title={"Grand Total (in words)"}
                description={salesOrderInfo?.in_words}
              />
            </div>

            <div>
              <InfoWithHeaderTitle
                title={"House Description"}
                description={salesOrderInfo?.items[0]?.description}
              />
              <InfoWithHeaderTitle
                title={"House Quantity"}
                description={salesOrderInfo?.items[0]?.qty}
              />
            </div>

            <div>
              <div className="mb-10">
                <input
                  onChange={formik.handleChange}
                  value={formik.values.amount}
                  id="amount"
                  name="amount"
                  type="number"
                  className="w-full rounded-r-lg rounded-l-lg p-4 border-t mr-0 border-b border-r border-l text-gray-800 border-gray-200 bg-white"
                  placeholder="NGN 10,000,000"
                />
              </div>

              <div>
                <Button
                  onClick={formik.handleSubmit}
                  btnColor="bg-msssl"
                  textColor="text-white"
                  text="MAKE PAYMENT NOW"
                  icon={formik.isSubmitting? faSpinner: faCreditCard}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>Loading....</div>
        )}
      </div>
    </LandingPageContainer>
  );
}

function InfoWithHeaderTitle({ title, description, subTitle }) {
  return (
    <div className="flex flex-row mb-3 content-start">
      <div className="text-lg font-bold w-2/6">{title}: </div>
      <div className="text-[16px] w-3/6 ">{description}</div>
      <div>{subTitle}</div>
    </div>
  );
}

export default SalesDetailPage;
