const getUserInfo = (user) => {
  const { userType } = user;
  return {
    ...user,
  };
};

export function setSalesOrder(payload) {
  return {
    type: "SET_LGAS",
    payload
  };
}

export const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: getUserInfo(action.user),
      };
	  case "SET_SALE_ORDER":
      return {
		  ...state,
		  salesOrder: action.payload
      };
    default:
      return {
        ...state,
      };
  }
};
