export const logout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("@info");
  localStorage.removeItem("@capture");
  localStorage.removeItem("@staffId");
  localStorage.removeItem("@witch");
  localStorage.removeItem("@myCount")

  return false;
};

export const getJWT = () => {
  return localStorage.getItem("access_token");
};

export const storeAuthentication = (data) => {

  localStorage.setItem("access_token", data.access_token);
  localStorage.setItem("@info", data.username);
  const { isAdminLogin } = data;
  localStorage.setItem("@witch", isAdminLogin ? "super" : "normal");
};

export const isAuthenticated = () => {
  // TODO if the token exist verify token from the server
  return localStorage.getItem("access_token");
};

export const isAdminUser = () => {
  const val = localStorage.getItem("@witch");
  return val && val === "super";
};

export const getApiDomain = () => {
  const client = window.location.hostname.split(".")[0]
  if (window.location.hostname.includes("local")) {
    return  `http://localhost:4445/${client}`;
  }
  return `https://integration-api.greenerp.ng/api/${client}`;
};
