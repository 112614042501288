import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router-dom";

const Button = ({
  btnColor = "bg-white",
  textColor = "text-white",
  text,
  onClick,
  to,
  icon,
  isDisabled = false,
  btnWidth = "100%"
}) => {
  const history = useHistory();
  const handleClick = () => {
    if (isDisabled) {
      return
    }
    if (onClick) {
      onClick();
    } else {
      history.push(to);
    }
  };
  return (
    <div className="relative cursor-pointer" onClick={handleClick}>
      <div
        className="absolute bg-black top-0.5 -right-0.5 -bottom-0.5 left-0.5"
        style={{ transformOrigin: "50% 50% 0px", borderRadius: "0%", width: btnWidth }}
      ></div>
      <div
        className={`${btnColor}  relative text-sm border cursor-pointer`}
        style={{
          height: "40px",
          borderColor: "rgb(0, 0, 0)",
          transformOrigin: "50% 50% 0px",
          borderRadius: "0%",
        }}
      >
        <span
          className="
                absolute
                inset-0
                flex
                items-center
                justify-center
                text-black
                tracking-wide
                uppercase
                RobotoMono-Bold_font__226og
                "
          style={{ opacity: 1 }}
        >
          <span styles={{ transformOrigin: "0% 50% 0px" }}>
            <span className={`${textColor}`}>{text}</span>
            {icon && <FontAwesomeIcon className={`ml-3 ${textColor}`} icon={icon} />}
          </span>
        </span>
      </div>
    </div>
  );
};

export default Button;
