import { faCreditCard, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { getCustomerInformationBySalesOrder } from "apis";
import { useFormik } from "formik";
import React from "react";
import Button from "ui/Button";
import LandingPageContainer from "./LandingPageContainer";
import { useHistory } from "react-router-dom";

const IntegrationLandingPage = () => {
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      salesOrderName: "",
    },

    async onSubmit(values) {
      const res = await getCustomerInformationBySalesOrder(
        values?.salesOrderName
      );
      if (res?.name) {
        history.push(`/sales-order/${res?.name}`);
      } else {
        alert("Error no sales order found");
      }
    },
  });

  return (
    <LandingPageContainer>
      <div className="flex flex-col">
        <div className="mb-2 text-green-400 flex flex-col">
          <img
            className="object-covedr"
            alt="landingpage"
            src="https://www.modernshelterng.com/img/msss.png"
          />
          <h1
            className={
              "text-3xl uppercase ml-3 text-msssl font-bold text-center "
            }
          >
            Modern Shelter Systems & Services Payment Portal
          </h1>
        </div>

        <div className="w-full mt-20">
          <div className="text-center font-bold text-indigo-500">
            <div className="mb-10">
              <input
                onChange={formik.handleChange}
                value={formik.values.salesOrderName}
                id="salesOrderName"
                name="salesOrderName"
                className="w-full rounded-r-lg rounded-l-lg p-4 border-t mr-0 border-b border-r border-l text-gray-800 border-gray-200 bg-white"
                placeholder="SAL-ORD-12333"
              />
            </div>
          </div>

          <Button
            onClick={formik.handleSubmit}
            btnColor="bg-msssl"
            textColor="text-white"
            text="GET SALES INFORMATION"
            icon={formik.isSubmitting? faSpinner: faCreditCard}
            btnWidth="100%"
          />
        </div>
        <div className="mb-auto self-center text-sm font-sans mt-3">
          Copyright © GreenERP 2024
        </div>
      </div>
    </LandingPageContainer>
  );
};
export default IntegrationLandingPage;
