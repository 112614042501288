import React, { useReducer } from "react";
import AuthContext from "./AuthContext";
import { reducer } from "./reducer";



const AuthProvider = ({ children }) => {
	const [contextData, dispatch] = useReducer(reducer, { user: {} });	
	return (
		<AuthContext.Provider value={{ contextData, dispatch}}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;