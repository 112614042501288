import AuthProvider from "context/AuthProvider";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import IntegrationLandingPage from "pages/integrations/LandingPage";
import SalesDetailPage from "pages/integrations/SalesDetailsPage";
function App() {
  return (
    <div className="min-h-screen bg-gray-100 bg-opacity-60">
      <Router>
        <AuthProvider>
          <Switch>
            <Route exact path="/" component={IntegrationLandingPage} />
            <Route path="/sales-order/:saledOrderId" component={SalesDetailPage}/>
            {/* <Route path="/admin" component={AdminRoutes} /> */}
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
